import { createContext, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./Dashboard";
import New from "./New";
import View from "./View";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />
  },
  {
    path: "/new",
    element: <New />
  },
  {
    path: "/:taskId",
    element: <View />
  }
]);

export const TelegramContext = createContext();

export default function App() {
  const [telegram] = useState(window.Telegram.WebApp);
  const styles = {
    color: telegram.themeParams.text_color,
    backgroundColor: telegram.themeParams.bg_color
  };
  return (
    <TelegramContext.Provider value={telegram}>
      <main style={styles}>
        <RouterProvider router={router} />
      </main>
    </TelegramContext.Provider>
  );
}
