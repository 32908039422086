import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TelegramContext } from "./App";
import { useQuery } from "@apollo/client";
import { GET_ALL_TASKS } from "./data/telegramtasks";
import moment from "moment";

const Dashboard = () => {
  const navigate = useNavigate();
  const data = useContext(TelegramContext);
  const ownerId = data.initDataUnsafe.user?.id.toString() ?? "-1";

  const { data: open, loading: loadingOpen } = useQuery(GET_ALL_TASKS, {
    variables: {
      ownerId,
      isCompleted: false
    }
  });
  const { data: closed, loading: loadingClosed } = useQuery(GET_ALL_TASKS, {
    variables: {
      ownerId,
      isCompleted: true
    }
  });

  useEffect(() => {
    data.MainButton.setParams({ text: "New task" });
    data.MainButton.onClick(newtask);
    data.MainButton.show();
    data.BackButton.hide();
  }, []);

  const newtask = () => {
    navigate("/new");
  };

  const openTaskItem = (task, index) => (
    <Link key={index} to={task.id}>
      <div className="p-3">
        <div>{task.title}</div>
        <small style={{ color: data.themeParams.hint_color }}>
          {task.reminderTime ? (
            <span role="img" aria-label="bell">
              🔔 {moment.unix(task.reminderTime).fromNow()}
            </span>
          ) : (
            <span>No reminders set</span>
          )}
        </small>
      </div>
    </Link>
  );

  const closedTaskItem = (task, index) => (
    <Link key={index} to={task.id}>
      <div key={index} className="p-3 opacity-50">
        <div className="line-through">{task.title}</div>
      </div>
    </Link>
  );

  const shining = () => <div>Loading</div>;

  return (
    <div>
      <div
        className="px-3 py-1.5 font-semibold tracking-wide text-xs"
        style={{
          backgroundColor: data.themeParams.secondary_bg_color,
          color: data.themeParams.hint_color
        }}
      >
        Open
      </div>
      <div className="divide-y">
        {loadingOpen
          ? shining()
          : open.telegramTasks.map((task, index) => openTaskItem(task, index))}
      </div>
      <div
        className="px-3 py-1.5 font-semibold tracking-wide text-xs"
        style={{
          backgroundColor: data.themeParams.secondary_bg_color,
          color: data.themeParams.hint_color
        }}
      >
        Closed
      </div>
      <div className="divide-y">
        {loadingClosed
          ? shining()
          : closed.telegramTasks.map((task, index) =>
              closedTaskItem(task, index)
            )}
      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

export default Dashboard;
