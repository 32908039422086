import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TelegramContext } from "./App";
import { useMutation } from "@apollo/client";
import { CREATE_TASK } from "./data/telegramtasks";
import TextareaAutosize from "react-textarea-autosize";
import ReminderInput from "./components/ReminderInput";

const New = () => {
  const navigate = useNavigate();
  const data = useContext(TelegramContext);
  const ownerId = data.initDataUnsafe.user?.id.toString() ?? "-1";

  const [createTask, { data: mdata }] = useMutation(CREATE_TASK);

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    reminderTime: null,
    ownerId
  });

  useEffect(() => {
    data.MainButton.hide();
    data.BackButton.onClick(cancel);
    data.BackButton.show();
  }, []);

  useEffect(() => {
    if (mdata && "id" in mdata.createTelegramTask) {
      navigate("/");
      window.location.reload();
    }
  }, [mdata, navigate]);

  const handleTitleChange = (e) => {
    setNewTask({ ...newTask, title: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setNewTask({ ...newTask, description: e.target.value });
  };

  const handleReminderChange = (reminderTime) => {
    setNewTask({ ...newTask, reminderTime: reminderTime });
  };

  const saveTask = () => {
    createTask({ variables: { ...newTask } });
  };

  const cancel = () => {
    navigate("/");
  };
  return (
    <div className="h-screen">
      <input
        style={{
          backgroundColor: data.themeParams.bg_color
        }}
        className="w-full text-lg font-medium p-4 focus:ring-0 focus:outline-none"
        placeholder="Enter task title"
        type="text"
        value={newTask.title}
        onChange={handleTitleChange}
      />

      <TextareaAutosize
        style={{ backgroundColor: data.themeParams.bg_color }}
        className="w-full p-4 focus:ring-0 focus:outline-none"
        placeholder="Enter task description"
        value={newTask.description}
        onChange={handleDescriptionChange}
      />

      <ReminderInput
        value={newTask.reminderTime}
        onChange={handleReminderChange}
      />

      <div className="p-4">
        <button
          className="w-full px-1.5 py-3 rounded font-medium"
          style={{
            backgroundColor: data.MainButton.color,
            color: data.MainButton.textColor
          }}
          onClick={saveTask}
        >
          Save Task
        </button>
      </div>
    </div>
  );
};

export default New;
