import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { TelegramContext } from "../App";

const ReminderInput = ({ value, onChange }) => {
  const data = useContext(TelegramContext);
  const [reminder, setReminder] = useState({
    timestamp: value,
    isEditing: false
  });

  useEffect(() => setReminder((r) => ({ ...r, timestamp: value })), [value]);

  const handleDateChange = (e) => {
    const time = moment.unix(reminder.timestamp).format("HH:mm");
    const timestamp = moment(
      `${e.target.value} ${time}`,
      "YYYY-MM-DD HH:mm"
    ).unix();
    onChange(timestamp);
  };
  const handleTimeChange = (e) => {
    const date = moment.unix(reminder.timestamp).format("YYYY-MM-DD");
    const timestamp = moment(
      `${date} ${e.target.value}`,
      "YYYY-MM-DD HH:mm"
    ).unix();
    onChange(timestamp);
  };

  const toggleEditing = () => {
    if (reminder.timestamp == null) {
      setReminder({
        timestamp: moment().unix(),
        isEditing: !reminder.isEditing
      });
    } else {
      setReminder({
        ...reminder,
        isEditing: !reminder.isEditing
      });
    }
  };

  const clearReminder = () => {
    setReminder({ ...reminder, isEditing: false });
    onChange(null);
  };

  const displayReminderTime = (
    <small
      className="font-semibold"
      style={{ color: data.themeParams.hint_color }}
    >
      {reminder.timestamp
        ? `${moment
            .unix(reminder.timestamp)
            .format("ddd, D MMM YYYY, h:mm a")} (${moment
            .unix(reminder.timestamp)
            .fromNow()})`
        : "No reminders set"}
    </small>
  );

  const editReminderTime = (
    <div>
      <input
        style={{ backgroundColor: data.themeParams.bg_color }}
        type="date"
        className="mr-2"
        name="reminderDate"
        value={moment.unix(reminder.timestamp).format("YYYY-MM-DD")}
        onChange={handleDateChange}
      />
      <input
        style={{ backgroundColor: data.themeParams.bg_color }}
        type="time"
        className="mr-2"
        name="reminderTime"
        value={moment.unix(reminder.timestamp).format("HH:mm")}
        onChange={handleTimeChange}
      />
      <button type="button" onClick={clearReminder}>
        Clear
      </button>
    </div>
  );

  return (
    <div className="px-4 py-2 flex">
      <div className="flex-1">
        <div>Reminder Time</div>
        {reminder.isEditing ? editReminderTime : displayReminderTime}
      </div>
      <div className="flex items-center">
        <button
          className="text-xs px-2 py-1 rounded"
          style={{
            backgroundColor: data.MainButton.color,
            color: data.MainButton.textColor
          }}
          onClick={toggleEditing}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default ReminderInput;
