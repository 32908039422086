import { gql } from "@apollo/client";

export const GET_ALL_TASKS = gql`
  query TelegramTasks($ownerId: ID!, $isCompleted: Boolean) {
    telegramTasks(ownerId: $ownerId, isCompleted: $isCompleted) {
      id
      title
      isCompleted
      reminderTime
    }
  }
`;

export const GET_TASK = gql`
  query TelegramTask($id: ID!) {
    telegramTask(id: $id) {
      id
      title
      description
      isCompleted
      reminderTime
      createdTime
      updatedTime
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTelegramTask(
    $title: String!
    $ownerId: ID!
    $description: String
    $reminderTime: Timestamp
  ) {
    createTelegramTask(
      title: $title
      ownerId: $ownerId
      description: $description
      reminderTime: $reminderTime
    ) {
      id
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTelegramTask(
    $id: ID!
    $title: String
    $description: String
    $isCompleted: Boolean
    $reminderTime: Timestamp
  ) {
    updateTelegramTask(
      id: $id
      title: $title
      description: $description
      isCompleted: $isCompleted
      reminderTime: $reminderTime
    ) {
      id
    }
  }
`;

export const SEND_REMINDERS = gql`
  mutation SendReminders {
    sendReminders {
      id
    }
  }
`