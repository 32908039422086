import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TelegramContext } from "./App";
import TextareaAutosize from "react-textarea-autosize";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TASK, UPDATE_TASK } from "./data/telegramtasks";
import ReminderInput from "./components/ReminderInput";
const View = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [task, setTask] = useState({});
  const data = useContext(TelegramContext);
  const { data: taskData } = useQuery(GET_TASK, {
    variables: { id: taskId },
  });

  const [updateTask, { data: mdata }] = useMutation(UPDATE_TASK);

  useEffect(() => {
    // data.MainButton.setParams({ text: "Back to tasks" });
    // data.MainButton.onClick(saveAndExit);
    data.MainButton.hide();
    data.BackButton.onClick(cancel);
    data.BackButton.show();
  }, []);

  useEffect(() => {
    if (taskData && "telegramTask" in taskData) {
      setTask(taskData.telegramTask);
    }
  }, [taskData]);

  useEffect(() => {
    // mutation complete
    if (mdata && "updateTelegramTask" in mdata) {
      navigate("/");
      window.location.reload();
    }
  }, [mdata, navigate]);

  const handleTitleChange = (e) => {
    setTask({ ...task, title: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setTask({ ...task, description: e.target.value });
  };

  const handleReminderChange = (reminderTime) => {
    setTask({ ...task, reminderTime });
  };

  const handleStatusChange = () => {
    setTask({ ...task, isCompleted: !task.isCompleted });
  };

  const cancel = () => {
    navigate("/");
  };

  const saveAndExit = () => {
    updateTask({
      variables: {
        id: taskId,
        title: task.title,
        description: task.description,
        isCompleted: task.isCompleted,
        reminderTime: task.reminderTime,
      },
    });
  };
  return (
    <div className="h-screen">
      <input
        style={{
          backgroundColor: data.themeParams.bg_color,
        }}
        className="w-full text-lg font-medium p-4 focus:ring-0 focus:outline-none"
        value={task.title}
        onChange={handleTitleChange}
        placeholder="Enter task title"
        type="text"
      />

      <TextareaAutosize
        style={{ backgroundColor: data.themeParams.bg_color }}
        className="w-full p-4 focus:ring-0 focus:outline-none"
        placeholder="Enter task description"
        value={task.description}
        onChange={handleDescriptionChange}
      />

      <div className="px-4 py-2 flex">
        <div className="flex-1">
          <div>Status</div>
          <small
            className="font-semibold"
            style={{ color: data.themeParams.hint_color }}
          >
            {task.isCompleted ? "Closed" : "Open"}
          </small>
        </div>
        <div className="flex items-center">
          <button
            className="text-xs px-2 py-1 rounded"
            style={{
              backgroundColor: data.MainButton.color,
              color: data.MainButton.textColor,
            }}
            onClick={handleStatusChange}
          >
            Toggle
          </button>
        </div>
      </div>

      <ReminderInput
        value={task.reminderTime}
        onChange={handleReminderChange}
      />

      <div className="px-4 py-2">
        <div>Updated Time</div>
        <small
          className="font-semibold"
          style={{ color: data.themeParams.hint_color }}
        >
          {moment.unix(task.updatedTime).fromNow()}
        </small>
      </div>

      <div className="px-4 py-2">
        <div>Created Time</div>
        <small
          className="font-semibold"
          style={{ color: data.themeParams.hint_color }}
        >
          {moment.unix(task.createdTime).fromNow()}
        </small>
      </div>

      <div className="p-4">
        <button
          className="w-full px-1.5 py-3 rounded font-medium"
          style={{
            backgroundColor: data.MainButton.color,
            color: data.MainButton.textColor,
          }}
          onClick={saveAndExit}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default View;
